import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { graphql } from "gatsby";
import Websiteface from "../images/website.jpg"
import PostLink from "../components/post-linkh";
import styled from "styled-components";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

theme.typography.h1 = {
  fontSize: '1.75rem',
  fontWeight: 400,
  '@media (min-width:600px)': {
    fontSize: '1.75rem',
    fontWeight: 400,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
    fontWeight: 400,
  },
};
theme.typography.h2 = {
  fontSize: '1.5rem',
  fontWeight: 400,
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
    fontWeight: 400,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.75rem',
    fontWeight: 400,
  },
};

const Bline = styled.hr`
  width: 70px;
  border-color: #96cefd;
  border-width: thin;
  `
const  Tips =  ({
    pageContext,
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
    const { currentPage, numPages } = pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
 
  return (
  <Layout>
          {!isFirst && (
            <SEO title={`Page ${currentPage} Blog Web Design Tips Items | blog.chennaihost.com `} description={`Page ${currentPage} chennaihost Blog Web Design Tips Items `} image={Websiteface} pathname={`/category/web-design-tips/${currentPage}/`} article />
          )}
            {isFirst && (
                <SEO title="Website Design Tips, CSS Tips, Case Study | blog.chennaihost.com
                " description="Website Design Tips, CSS Tips, CSS Frameworks
                " image={Websiteface} pathname="/category/web-design-tips/" article />
)}

    <ThemeProvider theme={theme}>
<Box mt={2} mb={10}>
    
    <Container>
    <Grid container spacing={2} >
    <Grid item xs={12}>
    {!isFirst && (
        <Typography  gutterBottom variant="h1" align="center">
            {`Web Design Tips - Page ${currentPage}`}
            </Typography>
          )}
           {isFirst && (
              <Typography  gutterBottom variant="h1" align="center"> 
            Web Design Tips
            </Typography>
          )}
    
    <Bline/>
        </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="stretch" >
    
    {Posts}
    <Grid item xs={12} align='center'>
          {!isFirst && (
            <Link to={`category/web-design-tips/${prevPage}/`} rel="prev">
               <Button
        variant="contained"
        color="primary"
        startIcon={<ChevronLeftIcon />}
        style={{ marginRight:7 }}
      >
        Previous
      </Button>
            </Link>
          )}
         
          {!isLast && (
            <Link to={`category/web-design-tips/${nextPage}/`} rel="next">
               <Button
        variant="contained"
        color="secondary"
        endIcon={<ChevronRightIcon />}
      >
        Next 
      </Button>
            </Link>
          )}
    </Grid>   
    </Grid>
        
    </Container>
    </Box>
    </ThemeProvider>
  </Layout>
  )
}
export default Tips
export const tipsQuery = graphql`
query tipsQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { category: {eq: "Web Design Tips"}}}
      limit: $limit
      skip: $skip
      ) {
      edges {
        node {
          id
          excerpt(pruneLength: 245)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            featuredImage {
              publicURL
              childImageSharp {
                sizes(maxWidth: 800 ) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`